import { Component,  OnInit, inject} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MakePayment } from "@app/shared/interfaces/make-payment.interface";
import { StripeService } from "@app/shared/services/stripe.service";
import { ReviewPaymentComponent } from "../review-payment/review-payment.component";
import { dialogConfig } from "@app/core/const/dialog.const";
import { UserService } from "@app/shared/services";
import { PaymentStore } from "@app/pages/payments/+data-access/payment.store";
;

@Component({
  selector: "app-make-payment",
  templateUrl: "./make-payment.component.html",
  styles: `
    .radio-button {
      position: relative;
      height: 100%;
      padding: 1rem;
      border-radius: 9px;
      border: 1px solid rgba(143, 134, 147, 0.3);
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      cursor: pointer;  
    }
  `,
})
export class MakePaymentComponent implements OnInit {
  selectedPayment: string = "balance";
  fb = inject(FormBuilder);
  amount: number = 0;
  currentDate: Date = new Date();
  processingFee: number = 4;
  method!: {id: string, clientSecret: string, stripePaymentMethodId: string};

  #dialog = inject(MatDialog);
  #dialogRef = inject(MatDialogRef);
  #stripeService = inject(StripeService);
  userService = inject(UserService);
  store = inject(PaymentStore);

  ngOnInit(): void {
    this.#checkBalance();
  }

  paymentMethod(method: {id: string, clientSecret: string, stripePaymentMethodId: string}): void {
    this.method = method;
  }
  
  makePayment(): void {
    this.#dialog.open(ReviewPaymentComponent, {
      ...dialogConfig.addDialogStd
    })
  }

  setAmount(amount: string): void {
    this.store.amount.set(parseFloat(amount.replace('$', '')));
  }

  #checkBalance(): void {
    this.userService.checkUserBalance().subscribe({
      next: resp => {
        this.amount = resp['balance'];
      }
    })
  }

  #submitPayment(): void {
    const payload = this.#generatePayload();
    this.#stripeService.createPaymentIntent(payload).subscribe({
      next: resp => {
        console.log(resp);
      }
    })
  }

  #generatePayload(): MakePayment {
    let payload: MakePayment = {
      amount: this.amount,
      paymentMethodId : this.method.id,
    }

    return payload;
  }
}
