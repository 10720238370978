import { PaymentMethod } from "@app/shared/interfaces/payment-method-list.interface";
import {
  Component,
  DestroyRef,
  OnInit,
  computed,
  inject,
  input,
  model,
  output,
  signal,
} from "@angular/core";
import { RoamIconComponent } from "../../roam-icon/roam-icon.component";
import { MatDialog } from "@angular/material/dialog";
import { SelectPaymentMethodComponent } from "../select-payment-method/select-payment-method.component";
import { dialogConfig } from "@app/core/const/dialog.const";
import { PaymentMethodService } from "@app/shared/services/payment-method.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { share } from "rxjs";
import { ButtonAddComponent } from "../../button/button-add/button-add.component";
import { LlcStripeDialogComponent } from "../../roam-dialog/llc-stripe-dialog/llc-stripe-dialog.component";
import { AddPaymentMethodComponent } from "../add-payment-method/add-payment-method.component";
import { PaymentStore } from "@app/pages/payments/+data-access/payment.store";

@Component({
  selector: "payment-method-input",
  standalone: true,
  imports: [RoamIconComponent, ButtonAddComponent],
  template: `
  @if(paymentMethods().length > 0) {
    <div class="payment-method">
      <div class="input">
        <div class="icon-brand">
          <roam-icon [src]="false" [name]="brand() ? 'payment/' + brand() : null" size="40" />
        </div>
        <div class="align-center gap-4px tc-black capitalized">
          <span>{{ label() }}</span>
          <span>••••{{ last4() }}</span>
        </div>
        @if (isDefault()) {
          <div class="default">Default</div>
        }
      </div>

      <button class="btn-base c-pointer" (click)="changePaymentMethod()">
        Change
      </button>
    </div>
  } @else {
    <app-button-add label="Add Payment Method" (onAdd)="addPayment()"/>
  }
   
  `,
  styles: `
    .payment-method {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      border-radius: 10px;
      border: 1px solid rgba(143, 134, 147, 0.5);
      background: #fff;
      padding: 4px 10px;
      height: 45px;

      .input {
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      .default {
        display: inline-flex;
        border-radius: 4px;
        background: #876486;
        color: #fff;
        font-weight: 600;
        padding: 2px 6px;
      }
    }
  `,
})
export class PaymentMethodInputComponent implements OnInit {
  id = model();
  brand = model();
  label = model<string>();
  last4 = model<string>();
  isDefault = model<boolean>();
  selectedMethod = output<{id: string, clientSecret: string, stripePaymentMethodId: string}>();

  paymentMethods = signal<PaymentMethod[]>([]);
  isLoading = signal<boolean>(false);

  #destroyRef = inject(DestroyRef);
  #paymentService = inject(PaymentMethodService);
  store = inject(PaymentStore);

  #dialog = inject(MatDialog);

  ngOnInit(): void {
    this.#getPaymentList();
  }

  #initPayment(): void {
    const method: PaymentMethod = this.paymentMethods().find(payment => payment.isDefault)!;
    this.#default(method);
    this.#setPaymentMethod(method);
  }

  #default(method: PaymentMethod): void {
    this.brand.set(null);
    this.brand.set(method.brand);
    this.label.set(method.brand);
    this.last4.set(method.last4);
    this.isDefault.set(method.isDefault);
    this.#setPaymentMethod(method);
    this.store.cardHolder.set(method.cardholderName);
    this.store.paymentMethodId.set(method.id);
  }

  #setPaymentMethod(method: PaymentMethod): void {
    this.selectedMethod.emit({
      id : method.id,
      clientSecret: 'pi_3QHFlpQ3kpTman9U11Ih1xy8_secret_1xMDLUn1b3UIxyo5rugoWZ2mS',
      stripePaymentMethodId: method.stripePaymentMethodId
    });
  }

  changePaymentMethod(): void {
    this.#dialog
      .open(SelectPaymentMethodComponent, {
        ...dialogConfig.addDialogStd,
        minHeight: "45vh",
        data: {
          payments: this.paymentMethods()
        }
      })
      .afterClosed()
      .subscribe((method: PaymentMethod) => {
        if (method) {
          this.#default(method);
        }
      });
  }

  addPayment(): void {
    this.#dialog
      .open(LlcStripeDialogComponent, {
        ...dialogConfig.dialogStd,
      })
      .afterClosed()
      .subscribe(state => {
        if (state) {
          this.openPaymentMethodDialog();
        }
      });
  }

  openPaymentMethodDialog(): void {
    this.#dialog
      .open(AddPaymentMethodComponent, {
        ...dialogConfig.addDialog,
        maxWidth: "700px",
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.#getPaymentList();
        }
      });
  }

  #getPaymentList(): void {
    this.#paymentService
      .paymentMethod()
      .pipe(takeUntilDestroyed(this.#destroyRef), share())
      .subscribe({
        next: resp => {
          this.paymentMethods.set(resp);
          this.isLoading.set(false);
          this.#initPayment();
        },
      });
  }
}
