import { computed, Injectable, signal } from "@angular/core";
import { single } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PaymentStore {
  readonly cardHolder = signal<string>('');
  readonly paymentMethodId = signal<string>('');
  readonly amount = signal<number>(0);
  readonly fixedFee = signal<number>(0.30);
  readonly stripeCCFee = signal<number>(0.029);
  readonly currentDate = signal<Date>(new Date());

  processingFee = computed(() => {
    const totalCharge = (this.amount() + 0.30) / (1 - 0.029);
    const fee = totalCharge - this.amount();
    return fee;
  });

  totalPayment = computed(() => {
    const stripeFee = (this.amount() + this.fixedFee()) / (1 - this.stripeCCFee());
    return stripeFee;
  });
}

